/**
 * @Numerik
 */

import React, { useState } from "react"

const Projection = () => {
  const [video, setVideo] = useState(false)

  function hideModal(e) {
    e.preventDefault()
    if (e.target.id === "videowrapper") {
      setVideo(false)
    }
  }

  const YoutubeVideo = () => {
    return (
      <div className="m-modal" id="m--modal" onClick={hideModal}>
        <div className="m-modal-content videoWrapper" id="videowrapper">
          <iframe
            width="100%"
            height="auto"
            src="https://www.youtube.com/embed/2Yl39aq1kOU"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen={true}
          ></iframe>
        </div>
      </div>
    )
  }

  return (
    <>
      {video && <YoutubeVideo />}
      <div className="hp--prjcts">
        <div className="hpp--wrapper">
          <div className="img-container">
            <div className="ic--wrapper">
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault()
                  setVideo(true)
                }}
                className="link-btn "
              >
                <i className="fa-solid fa-circle-play pulse"></i>
                <h3 className="video-hint">Voir la vidéo </h3>
              </a>
            </div>
          </div>
          <div className="hpp-cap-container">
            <h1 className="hpp--title">
              Résumé de la <span> vidéo</span>
            </h1>
            <div className="separator "></div>
            <p>La vidéo promotionnelle de PDTK résume : </p>
            <ol className="">
              {[
                {
                  title:
                    " Les conditions et les problèmes actuels de transport dans la ville de Kinshasa",
                },
                {
                  title:
                    " Un exemple de routine de déplacement le matin sans le plan Directeur",
                },
                {
                  title:
                    " Les conditions de transport en 2030 sans plan directeur",
                },
                {
                  title: "Introduction du plan directeur",
                },
                {
                  title:
                    "Les Conditions de transport en 2030 avec le Plan Directeur",
                },
                {
                  title:
                    " Un exemple de la routine de déplacement le matin en 2030 avec du Plan Directeur",
                },
                {
                  title: "Entretiens avec les homologues de plusieurs projets",
                },
              ].map((item, d) => (
                <li key={d}>
                  <h3>{item.title}</h3>
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>
    </>
  )
}

export default Projection
