import toast from 'react-hot-toast';



export const makeToastNotification = ({
    type,
    message,
    duration = 3000,
    position = 'top-center',
    style = {},
    className = "",
    iconTheme = {},
    ariaProps = {},
}) => {
    toast[type ? type : 'success'](message, {
        duration: duration,
        position: position,
        style: style,
        className: className,
        iconTheme: iconTheme,
        ariaProps: ariaProps,


    });
}
