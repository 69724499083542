/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/home/PathPlan.js
Created On : 16 May 2022 17:59:46
*/

import React from "react"

const PathPlan = () => {
  return (
    <div className="hp--sectors">
      <div className="s--wrapper">
        <h1 className="hp--title">CARTE DU PLAN DIRECTEUR POUR 2030</h1>
        <div>
          <img src="/imgs/secteurs/brt.png" alt="carte" className="w-100" />
        </div>
      </div>
    </div>
  )
}

export default PathPlan
