/**
 * @Numerik
 */

import React from "react"
import { Link } from "react-router-dom"
import { Documentation } from "../../routes"

const Hiro = () => {
  return (
    <div className="hp--hiro">
      <img src="/imgs/bg/hiro.svg" className="hiro-svg-bg" alt="" />
      <div className="hp--wrapper">
        <div className="img-wrapper">
          <img src="/imgs/logo-2.svg" className="hiro-logo" alt="" />
        </div>
        <div className="caption--wrapper">
          <small className="section--info"> PDTK</small>
          <h1 className="cw--title">
            Plan Directeur des <span>Transports urbains</span> de la ville de{" "}
            <span> Kinshasa</span>
          </h1>
          <h3 className="cw--subtitle">
            Le PDTK suggère des programmes à court, moyen et long terme à partir
            de 8 domaines : Le Bus Rapide (BRT), le rail, le bus et le
            para-transit , les routes, la gestion du traffic, la sécurité
            routière, le transport par voie fluviale, l'arrangement
            institutionnel et financier.{" "}
          </h3>
          <div className="actionner">
            <Link
              to={Documentation}
              className="btn btn-secondary btn-actionner"
            >
              En savoir plus
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hiro
