/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/sectors/Ferrovier.js
Created On : 16 May 2022 17:31:27
*/

import React from "react"
import { Col, Row } from "react-bootstrap"

const Ferrovier = () => {
  return (
    <div>
      <h2>2 projets ferroviaires</h2>
      <p>
        Il est proposé de rénover les lignes ferroviaires existantes pour
        devenir un service de transport urbain. La Ligne Sud sur Gare
        Centrale-Kasangulu sera réhabilitée pour relier le centre-ville de Gombe
        et la partie sud de Kinshasa. De plus, la ligne Est de la Gare
        Centrale-Ndjili Airport, abandonnée en 2015, sera réhabilitée pour
        répondre à la demande de transport entre le centre-ville et la partie
        Est des zones urbanisées de Kinshasa. Ces deux lignes serviront un
        service de transport fondamental le long de ces deux corridors de
        transport à forte demande de passagers.
      </p>
      <p>
        Pour atteindre le niveau de service de chemin de fer urbain, de
        nouvelles rames de train (DMU : Unité Multiple Diesel) sont proposées.
        Ces rames supplémentaires peuvent exploiter 6 déplacements aller-retour
        sur une section à double voie entre Kinshasa Est et Matete toutes les
        heures.
      </p>
      <p>
        Dans le but d’assurer la sécurité et la ponctualité de l’exploitation de
        train, le projet prévoit en outre l’installation du système de
        signalisation avec un ballast satisfaisant et le système de sécurité qui
        est basé sur les télécommunications sans fil pour identifier
        l’emplacement de train.{" "}
      </p>
      <div className="mb-3">
        <h1 className="h3 text-muted my-2">Projets proposés</h1>
        <p>
          <img
            src="/imgs/secteurs/p-ferrovier-01.png"
            alt="carte"
            className="w-100"
          />
        </p>
      </div>
      <div className="mb-3">
        <p>
          <img
            src="/imgs/secteurs/p-legend-01.png"
            alt="carte"
            className="w-100"
          />
        </p>
      </div>
      <Row>
        <Col md={12}>
          <h1 className="h3 text-muted my-2">2030</h1>
          <p>
            <img src="/imgs/secteurs/B032.png" alt="carte" className="w-100" />
          </p>
        </Col>
        <Col md={12}>
          <h1 className="h3 text-muted my-2">2040</h1>
          <p>
            <img
              src="/imgs/secteurs/ferrovier-map.png"
              alt="carte"
              className="w-100"
            />
          </p>
        </Col>
      </Row>
    </div>
  )
}

export default Ferrovier
