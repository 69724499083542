/*
    @Numerik
    Fichier : request.js
    Created on: 30 Janvier 2022 17:11:59
*/
import axios from 'axios'

/** */

function redirectTo(route = "/") {
    window.location.href = route;
}

/**  make request hook */

export function useMakesRequest() {

    function sendRequest({
        method = "get",
        route,
        data,
        setLoader,
        onSuccess,
        onError,
    }) {
        setLoader = setLoader ? setLoader : null;
        // try api request
        try {
            // loading
            manageLoader(setLoader, true);
            axios[method](route, data).then(response => {
                /**  request success */
                console.log(response);
                //set response
                if (onSuccess) {
                    onSuccess(response);
                }
                //loader
                manageLoader(setLoader, false);
            }).catch(error => {
                /**  request error */
                //
                if (onError) {
                    onError(error);
                }
                //loader
                manageLoader(setLoader, false);

            })
        } catch (error) {
            /**  request failed */
            console.log(error)
        }
    }

    return sendRequest;
}


function manageLoader(manager, value) {
    if (manager) {
        manager(value);
    }
}