/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/sectors/Maritime.js
Created On : 16 May 2022 17:36:52
*/
import React from "react"

const Maritime = () => {
  return (
    <div>
      <h2>2 Projets de transport par voie d’eau</h2>
      <p>
        En plus du service de chemin de fer et du BRT, le PDTK propose à court
        terme, deux projets de transport par voies navigables à savoir (1) un
        service de ferry et (2) le développement du port de Kinkole. Ce service
        de transport fluvial va relier le quartier d’affaire à partir du Beach
        Ngobila et le port de Kinkole (20 km). Ainsi, le Projet compte acquérir
        6 bateaux ferry pour l’exploitation.
      </p>
      <div className="mb-3">
        <h1 className="h3 text-muted my-2">Projets proposés</h1>
        <p>
          <img
            src="/imgs/secteurs/p-navigation-01.png"
            alt="carte"
            className="w-100"
          />
        </p>
      </div>
      <div className="mb-3">
        <p>
          <img
            src="/imgs/secteurs/p-legend-01.png"
            alt="carte"
            className="w-100"
          />
        </p>
      </div>
      <p>
        <img
          src="/imgs/secteurs/projets-maritime.png"
          alt="carte"
          className="w-100"
        />
      </p>
    </div>
  )
}

export default Maritime
