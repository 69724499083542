/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/sectors/Bus.js
Created On : 16 May 2022 17:32:37
*/
import React from "react"

const Bus = () => {
  return (
    <div>
      <h2>4 projets de bus et de transport adapté</h2>
      <p>
        La part des bus et des transports adaptés est la plus élevée parmi tous
        les modes de transport motorisés. Ils serviront de grands axes de
        transport public avant que le BRT et la modernisation des chemins de fer
        n’entrent en service. Par conséquent, la réforme des services de bus et
        de transport adapté (paratransit) est cruciale et urgente.{" "}
      </p>
      <p>
        Il est également attendu des bus et du transport adapté qu’ils
        fonctionnent en tant que service de rabattement des lignes de chemin de
        fer modernisées et des lignes de BRT.{" "}
      </p>
      <p>
        Bien que certaines lois sur les minibus, les taxi-bus et les taxis
        collectifs (Bus-2) ont été renforcées depuis 2018, notamment en matière
        de codes de couleur et d’autocollants des numéros d’immatriculation,
        l’élaboration de mesures de contrôle supplémentaires est attendue.
      </p>
      <p>
        Ainsi, les 4 projets de bus et de transport adapté se focaliseront donc
        sur :
        <ul>
          <li>L’aménagement de terminaux de bus et d’arrêts de bus ;</li>
          <li>
            Le contrôle strict des minibus, des taxis et des taxis collectifs ;
          </li>
          <li>
            La réforme institutionnelle de l’industrie des bus et des taxis et
          </li>
          <li>
            Le Renforcement de l’organisme de réglementation des bus et des
            taxis
          </li>
        </ul>
      </p>
      <div className="mb-3">
        <h1 className="h3 text-muted my-2">Projets proposés</h1>
        <p>
          <img
            src="/imgs/secteurs/p-paratransit-01.png"
            alt="carte"
            className="w-100"
          />
        </p>
      </div>
      <div className="mb-3">
        <p>
          <img
            src="/imgs/secteurs/p-legend-01.png"
            alt="carte"
            className="w-100"
          />
        </p>
      </div>
    </div>
  )
}

export default Bus
