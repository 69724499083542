/*
    @Numerik
    Fichier : index.js
    Created on: 31 Janvier 2022 13:18:11
*/
import React from 'react';
import Sections from './Sections';
const index = () => {
    return (
        <div className="my-brt-p brt-train">
            <div className="hiro-c">
                <div className="c--overlay">
                    <div className="h--wrapper"><h1>Mon Train</h1></div>
                </div>

            </div>
            <Sections />
        </div>
    );
};

export default index;