/*
    @Numerik
    Fichier : validation.js
    Created on: 30 Janvier 2022 17:44:38
*/

export function setValidationErrors(setErrors, errors) {
    errors = errors.response;
    if (errors.status === 422) {
        if (setErrors) {
            setErrors(errors.data.errors);
        }
    }

}