/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/sectors/Routiers.js
Created On : 16 May 2022 17:34:11
*/

import React from "react"
import { Col, Row } from "react-bootstrap"

const Routiers = () => {
  return (
    <div>
      <h2>78 projets routiers</h2>
      <p>
        Les projets routiers proposés peuvent être classés en deux catégories :
        les projets à court terme et les projets à moyen terme.{" "}
      </p>
      <ul>
        <li>
          <p>
            Les projets à court terme sont axés sur des projets ayant moins
            d’impacts environnementaux et sociaux, compte tenu du temps
            nécessaire à la préparation, particulièrement en matière
            d’acquisition des terrains et de relocalisation. Ces routes sont
            également des itinéraires pour camions dans la zone urbanisée
            actuelle.
          </p>
        </li>
        <li>
          <p>
            À moyen terme, il est proposé de mettre en place un réseau routier
            en élaborant des corridors de transport essentiels pour encourager
            et induire le développement urbain. Dans le cadre de l’élaboration
            du futur réseau routier de la ville de Kinshasa, le réseau routier
            sera en forme de grille et répondant à une classification
            hiérarchique comprenant : les voies périphériques, les axes routiers
            est-ouest, les axes routiers nord-sud, les Routes primaires, les
            routes secondaires et voies express.
          </p>
        </li>
      </ul>
      <div className="mb-3">
        <h1 className="h3 text-muted my-2">Projets proposés</h1>
        <p>
          <img
            src="/imgs/secteurs/p-route-01.png"
            alt="carte"
            className="w-100 mb-2"
          />
          <img
            src="/imgs/secteurs/p-route-02-01.png"
            alt="carte"
            className="w-100 mb-2"
          />
          <img
            src="/imgs/secteurs/p-route-03-01.png"
            alt="carte"
            className="w-100 mb-2"
          />
        </p>
      </div>
      <div className="mb-3">
        <p>
          <img
            src="/imgs/secteurs/p-legend-01.png"
            alt="carte"
            className="w-100"
          />
        </p>
      </div>
      <p>
        <h1 className="h3 text-muted my-2">Réseau routier stratégique</h1>
        <img
          src="/imgs/secteurs/78-projets-routiers.png"
          alt="carte"
          className="w-100"
        />
      </p>
      <Row>
        <Col md={12}>
          <h1 className="h3 text-muted my-2">2030</h1>
          <p>
            <img src="/imgs/secteurs/B031.png" alt="carte" className="w-100" />
          </p>
        </Col>
        <Col md={12}>
          <h1 className="h3 text-muted my-2">2040</h1>
          <p>
            <img src="/imgs/secteurs/B071.png" alt="carte" className="w-100" />
          </p>
        </Col>
      </Row>
    </div>
  )
}

export default Routiers
