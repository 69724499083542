/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/sectors/Brt.js
Created On : 16 May 2022 17:29:20
*/
import React from "react"
import { Col, Row } from "react-bootstrap"

const Brt = () => {
  return (
    <div>
      <h2>7 projets BRT</h2>
      <p>
        En plus du chemin de fer proposé, des corridors BRT, Bus Rapid Transit
        ou Bus à haut niveau de service, seront introduits sur les principales
        artères telles que le Boulevard Lumumba et le Boulevard 30 Juin, où la
        demande de transport public devrait devenir plus importante à l’avenir.
        Le BRT est un système de bus de haute qualité qui offre des services de
        transport rapides, confortables et rentables.
      </p>
      <p>
        Des voies dédiées avec des bus et des stations BRT alignées au centre de
        la route seront construites. Chaque station sera équipée d’une
        plate-forme d’embarquement avec un service de billetterie (Automates)
        qui permettront aux passagers d’acheter des titres de transport et de
        recharger des abonnements 7/7 j, 24h/24.
      </p>
      <p>
        En plus, pour assurer la ponctualité et éviter les embouteillages, un
        système de priorité pour les transports publics (PTPS), tel que des
        signaux prioritaires, sera installé. En effet, Même si le BRT utilise
        des voies réservées, l'exploitation du BRT est perturbée aux
        intersections. Cela peut entraîner des retards des bus et, parfois, des
        files d'attente de bus. Ainsi, le PTPS (Système de priorité des
        transports en commun / Public Transport Priority System) est recommandé
        pour le fonctionnement organisé du BRT. Le PTPS peut prolonger le temps
        du feu vert pour la direction du corridor du BRT au cas où un bus du BRT
        s'approche.
      </p>
      <p>
        Les nouvelles lignes de BRT formeront un réseau de transport dense pour
        favoriser la mobilité des Kinois.
      </p>
      <div className="mb-3">
        <h1 className="h3 text-muted my-2">Projets proposés</h1>
        <p>
          <img
            src="/imgs/secteurs/p-brt-01.png"
            alt="carte"
            className="w-100"
          />
        </p>
      </div>
      <div className="mb-3">
        <p>
          <img
            src="/imgs/secteurs/p-legend-01.png"
            alt="carte"
            className="w-100"
          />
        </p>
      </div>
      <Row>
        <Col md={12}>
          <h1 className="h3 text-muted my-2">2030</h1>
          <p>
            <img src="/imgs/secteurs/brt.png" alt="carte" className="w-100" />
          </p>
        </Col>
        <Col md={12}>
          <h1 className="h3 text-muted my-2">2040</h1>
          <p>
            <img src="/imgs/secteurs/B062.png" alt="carte" className="w-100" />
          </p>
        </Col>
      </Row>
    </div>
  )
}

export default Brt
