/**
 * @Numerik
 * developed sections
 */
import React from "react"

const Sections = () => {
  const Item = ({ caption, title, left = true, img, noImg = false }) => {
    return (
      <div className="sw--item-container">
        <div className={"sw--item " + (!left ? "reverse" : "")}>
          {left && !noImg && (
            <div className="sw--ic">
              <img src={img} />
            </div>
          )}
          <div className={"sw--content " + (noImg ? "w-100" : "")}>
            <h1 className="sw--title">{title}</h1>
            <p className="sw--caption">{caption}</p>
          </div>
          {!left && !noImg && (
            <div className="sw--ic">
              <img src={img} />
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <section className="wr--section">
      <div className="s--wrapper">
        <Item
          img="/imgs/train/map.jpg"
          caption="Le service ferroviaire actuel de la ville de Kinshasa est loin du service ferroviaire urbain en termes de fréquence, de vitesse, de capacité et de niveau de service.  Le PDTK prévoit une transformation fondamentale du service ferroviaire en transport urbain rapide pour satisfaire l’énorme demande de transport de passagers d’ici à 2030 et 2040."
          title="MON TRAIN"
          left={false}
        />
        <Item
          img="/imgs/train/mytrain.jpg"
          caption="Abandonnée en Septembre 2015 suite à des problèmes financiers, la ligne EST qui relie la Gare centrale et l’Aéroport de N’djili sera réhabilitée et modernisée pour assurer le transport entre le centre-ville de Kinshasa et les zones urbanisées de l’Est de la ville."
          title="Ligne Gare centrale-Aéroport de N’djili"
        />
        <Item
          noImg={true}
          caption="Exploitée de manière limitée, la ligne SUD qui relie la Gare centrale et la cité de Kasangulu dans le Kongo central est à ce jour la seule ligne encore en service. Elle est longue de 45 Km et passe par Kimwenza. Sa réhabilitation va permettre le transport de milliers de personnes et d’importantes charges de marchandise."
          title="Ligne Gare centrale-Kasangulu"
          left={false}
        />
      </div>
    </section>
  )
}

export default Sections
