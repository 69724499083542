/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/sectors/Security.js
Created On : 16 May 2022 17:36:00
*/

import React from "react"

const Security = () => {
  return (
    <div>
      <h2>11 Projets de sécurité routière</h2>
      <p>
        Dans la ville de Kinshasa, la marche à pied représente 48 % de la part
        modale, ce qui signifie qu’un grand nombre de piétons sont confrontés au
        risque d’accidents de circulation à mesure que les véhicules motorisés
        augmentent, en dépit de la médiocrité des infrastructures de sécurité.
        La stratégie de sécurité routière pour la ville de Kinshasa devrait
        mettre l’accent sur les usagers vulnérables, y compris les piétons,
        organisés selon les cinq piliers stratégiques sur la sécurité routière.
      </p>
      <p>
        Le développement et la mise en œuvre d’un système de base de données sur
        les accidents de la circulation routière seront mis en œuvre à très
        court terme (en urgence).
      </p>
      <p>
        pour que les conducteurs puissent les reconnaître de manière intuitive
        et continuer à conduire en toute sécurité, les panneaux de signalisation
        routière (signalisations verticales) et les marquages routiers (panneaux
        horizontaux) seront correctement installés aux intersections et le long
        des routes.
      </p>
      <p>
        Il convient également de noter que plusieurs projets de sécurité
        routière à court terme doivent être mis en œuvre dans des délais plus
        courts, comme : l’Introduction continue de l’éducation et de la
        sensibilisation à la sécurité routière ; l’Amélioration de l’équipement
        pour l’application de la loi, l’Introduction du système de points
        d’inaptitude pour le permis de conduire, l’Introduction de l’audit de
        sécurité routière obligatoire,…
      </p>
      <div className="mb-3">
        <h1 className="h3 text-muted my-2">Projets proposés</h1>
        <p>
          <img
            src="/imgs/secteurs/p-securite-01-01.png"
            alt="carte"
            className="w-100"
          />
        </p>
      </div>
      <div className="mb-3">
        <p>
          <img
            src="/imgs/secteurs/p-legend-01.png"
            alt="carte"
            className="w-100"
          />
        </p>
      </div>
    </div>
  )
}

export default Security
