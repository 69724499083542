/**
 * @Numerik
 *
 */

import React from "react"
import { Link } from "react-router-dom"
import {
  ABOUT,
  Contacts,
  DASHBOARD,
  Documentation,
  HOME,
  SECTORS,
} from "../routes"

const Footer = () => {
  const BottomBar = () => {
    return (
      <div className="b-bar">
        <div className="b--wrapper">
          <div className="credits">
            <p>
              {new Date().getFullYear()} &copy; <span>PDTK</span> . Tout droits
              reservés. - Développé par{" "}
              <a
                href="https://www.10gitalizer.com"
                alt="Developpé par 10gitlizer"
                target="_blank"
                rel="noreferrer"
                className="text-secondary"
              >
                10gitalizer
              </a>
            </p>
          </div>
          <ul className="socials">
            <li>
              <a
                href="https://www.facebook.com/PDTK-Kinshasa-108242641987568/"
                className="fb"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-facebook-f"></i>
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/Pdtk_kinshasa"
                className="tw"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-twitter"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCaJteV7BCt00Wr8Hod7LfMA"
                className="ytb"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-youtube"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/pdtk_kinshasa/"
                className="inst"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-instagram"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    )
  }

  return (
    <div className="m--ftr">
      <div className="mf--wrapper">
        <div className="s--abt">
          {/*  */}
          <div className="lg--ctr">
            <Link to="/">
              <img alt="" src="/imgs/logo-white-01.png" />
            </Link>
            <div className="caption-c">
              <p>
                le PDTK a été formulé avec l’assistance nationale du Japon en
                2018 et a été officiellement approuvé par le conseil provincial
                de Kinshasa en 2019.
              </p>
            </div>
            <div className="ss--contact">
              <h1 className="ss--title">Contact</h1>
              <ul>
                <li>
                  <a href="tel:+243 823 322 272">Tel : +243 823 322 272</a>
                </li>
                <li>
                  <a href="mailto:contact@pdtk-kinshasa.com">
                    E-mail : contact@pdtk-kinshasa.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="section m--links">
          <h1 className="s--title">Liens utiles</h1>
          <ul>
            <li>
              <Link to={HOME}>Accueil</Link>
            </li>
            <li>
              <Link to={ABOUT}>A propos du PDTK</Link>
            </li>
            <li>
              <Link to={SECTORS}>Secteurs</Link>
            </li>
            <li>
              <Link to={DASHBOARD}>Dashboard</Link>
            </li>
            <li>
              <Link to={Documentation}>Documentation</Link>
            </li>
            <li>
              <Link to={Contacts}> Contact</Link>
            </li>
          </ul>
        </div>
      </div>
      <BottomBar />
    </div>
  )
}

export default Footer
