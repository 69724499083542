/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/dashboard/Kinshasa.js
Created On : 16 May 2022 18:54:41
*/
import React, { useEffect } from "react"

const Kinshasa = () => {
  useEffect(() => {
    var divElement = document.getElementById("viz1656032442188")
    var vizElement = divElement.getElementsByTagName("object")[0]
    if (divElement.offsetWidth > 800) {
      vizElement.style.minWidth = "1024px"
      vizElement.style.maxWidth = "100%"
      vizElement.style.minHeight = "700px"
      vizElement.style.maxHeight = divElement.offsetWidth * 0.75 + "px"
    } else if (divElement.offsetWidth > 500) {
      vizElement.style.minWidth = "1024px"
      vizElement.style.maxWidth = "100%"
      vizElement.style.minHeight = "700px"
      vizElement.style.maxHeight = divElement.offsetWidth * 0.75 + "px"
    } else {
      vizElement.style.width = "100%"
      vizElement.style.height = "2300px"
    }
    vizElement.style.maxWidth = "100%"
    vizElement.style.width = "100%"
    var scriptElement = document.createElement("script")
    scriptElement.src = "https://public.tableau.com/javascripts/api/viz_v1.js"
    vizElement.parentNode.insertBefore(scriptElement, vizElement)
  }, [])

  return (
    <div
      className="tableauPlaceholder"
      id="viz1656032442188"
      // style={{ position: "relative" }}
    >
      <noscript>
        <a href="/">
          <img
            alt=" "
            src="https://public.tableau.com/static/images/Q9/Q9K4FZSQZ/1_rss.png"
            style={{ border: "none" }}
          />
        </a>
      </noscript>
      <object className="tableauViz" style={{ display: "none;" }}>
        <param name="host_url" value="https://public.tableau.com/" />
        <param name="embed_code_version" value="3" />
        <param name="site_root" value="" />
        <param
          name="name"
          value="PDTK-TBTableaudeBorddesTransportsKinshasaKinshasaTransportDashboard/CartedefluxFlowmap"
        />
        <param name="tabs" value="yes" />
        <param name="toolbar" value="yes" />
        <param
          name="static_image"
          value="https://public.tableau.com/static/images/PD/PDTK-TBTableaudeBorddesTransportsKinshasaKinshasaTransportDashboard/CartedefluxFlowmap/1.png"
        />
        <param name="animate_transition" value="yes" />
        <param name="display_static_image" value="yes" />
        <param name="display_spinner" value="yes" />
        <param name="display_overlay" value="yes" />
        <param name="display_count" value="yes" />
        <param name="language" value="fr-FR" />
        <param name="filter" value="Language+Param=Fr" />
      </object>
    </div>
  )
}

export default Kinshasa
