/**
 * @Numerik
 * developed sections
 */
import React from "react"

const Sections = () => {
  const Item = ({ caption, title, left = true, img }) => {
    return (
      <div className="sw--item-container">
        <div className={"sw--item " + (!left ? "reverse" : "")}>
          {left && (
            <div className="sw--ic">
              <img src={img} />
            </div>
          )}
          <div className="sw--content">
            <h1 className="sw--title">{title}</h1>
            <p className="sw--caption">{caption}</p>
          </div>
          {!left && (
            <div className="sw--ic">
              <img src={img} />
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <section className="wr--section">
      <div className="s--wrapper">
        <Item
          img="/imgs/brt/economy.jpg"
          caption="Le transport est un dérivé des activités économiques. Cependant, les activités économiques
dépendent totalement du transport des passagers et des marchandises dans les zones urbaines contemporaines.  Les obstacles au transport affectent de manière significative la croissance économique de la ville et de la nation. En tant que capitale, le dysfonctionnement de la ville de Kinshasa signifie un dysfonctionnement de l'administration et de l'économie de toute la RDC, un pays dont la superficie est comparable à celle de l'Europe occidentale. "
          title="Soutenir les activités économiques urbaines"
        />
        <Item
          img="/imgs/brt/equity.jpg"
          caption="Indépendamment de leur niveau de revenu, âge, sexe, handicap, de la disponibilité d’un véhicule ou de toute autre caractéristique personnelle, les résidents devraient disposer de moyens de transport pour faciliter leurs activités quotidiennes, à savoir travailler, étudier, manger, faire leurs courses et prendre soin de leur santé. En ce sens, le transport est un droit fondamental pour les personnes. La restriction de la mobilité entraîne une réduction de l'accessibilité aux opportunités d'emploi, à l'éducation et aux services médicaux."
          title="Assurer l’équité dans les transports"
          left={false}
        />
        <Item
          img="/imgs/brt/security.jpg"
          caption="La sécurité et la sûreté dans les transports sont une préoccupation pour tous, y compris les piétons, les conducteurs et les passagers. Bien que les accidents de la circulation ne soient jamais complètement éliminés, même dans les pays développés, des efforts continus sont nécessaires pour minimiser les risques d'accidents. Les crimes liés aux transports devraient également être pris en compte."
          title="Améliorer la sécurité et la sûreté"
        />
        <Item
          img="/imgs/brt/environement.jpg"
          caption="On souhaite que les systèmes de transports urbains soient respectueux de l'environnement. Sans cet objectif, les villes ne pourront pas être viables à l'avenir. Bien qu'il ne soit pas possible d'éliminer complètement tous les impacts négatifs sur l'environnement et la société, ils devraient être réduits ou mitigés à un niveau acceptable."
          title="Réaliser un transport respectueux de l’environnement"
          left={false}
        />
      </div>
    </section>
  )
}

export default Sections
