/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/pages/AboutPDTK.js
Created On : 16 May 2022 13:03:01
*/

import React from "react"

const AboutPDTK = () => {
  return (
    <div className="about-pdtk-p">
      <div className="ap--wrapper">
        <section>
          <div className="aps--wrapper">
            <h1>La ville de Kinshasa</h1>
            <div className="text-center text-muted">
              <div>
                <p>
                  La ville de Kinshasa est la capitale de la République
                  démocratique du Congo. Elle se situe sur la rive sud du fleuve
                  Congo, au niveau du Pool Malebo et s’étend sur 9 965 km2.{" "}
                </p>
                <p>
                  Démographiquement, c’est une mégalopole de plus de 12 millions
                  d’habitants. Elle devrait atteindre 20 millions d’ici à 2030
                  et 26 millions d’ici à 2040, devenant ainsi la deuxième ville
                  la plus peuplée d’Afrique subsaharienne après Lagos, au
                  Nigeria.
                </p>
                <p>
                  Sur de plan urbanistique, la ville connait une urbanisation
                  rapide, cependant, l’aménagement actuel des infrastructures
                  reste encore insuffisant, entrainant de ce fait une congestion
                  de trafic routier et une perte économique.{" "}
                </p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="aps--wrapper">
            <h1>Historique du PDTK</h1>
            <p className="h--caption">
              La ville de Kinshasa connait beaucoup des enjeux liés au transport
              urbain. La situation actuelle est tel que :{" "}
            </p>
            {/* s1 */}
            <div className="s--wrapper">
              <div className="img-c">
                <img
                  src="/imgs/about-pdtk/demande-de-trafic.jpg"
                  alt="Demande de trafic"
                />
              </div>
              <div className="d--content">
                <h3>La demande en trafic routier est croissante</h3>
                <p>
                  Tout comme la plupart des zones urbaines des pays développés
                  et émergents ont vécu, on s’attend à une motorisation rapide
                  et à une croissance économique dans les années avenirs. Ainsi,
                  selon les projections, le nombre de véhicules en 2040 est
                  supposé être 6,3 fois plus que celui de 2017. Il est également
                  à noter que le taux de déplacements, soit le nombre de
                  déplacements par jour, devrait augmenter en fonction de
                  l’augmentation du revenu et de la possession d’un véhicule
                  privé.
                </p>
                <p>
                  Il ne sera donc pas possible à l’avenir de réduire la
                  congestion du trafic avec le seul réseau routier existant. Des
                  contre-mesures fondamentales pour gérer l’augmentation de la
                  demande de déplacements seront nécessaires afin de réduire la
                  congestion du trafic et les facteurs économiques externes,
                  telles que les coûts du temps de déplacement, les coûts
                  d’exploitation des véhicules, les émissions de gaz à effet de
                  serre, la pollution atmosphérique et les accidents de
                  circulation.
                </p>
              </div>
            </div>
            {/* s2 */}
            <div className="s--wrapper">
              <div className="d--content">
                <h3>Le réseau routier est incomplet</h3>
                <p>
                  L’une des principales raisons de la congestion de la
                  circulation dans la ville de Kinshasa est que le réseau
                  routier existant est, dans la plupart de cas vétuste, en
                  mauvais état et inachevé. La circulation est concentrée sur un
                  nombre limité de routes reliées au centre-ville. À l’exception
                  de quelques routes comme le boulevard Lumumba et le boulevard
                  du 30 Juin, la majeure parte des artères à Kinshasa ne sont
                  suffisamment pas aménagées et elles sont étroites.
                </p>
                <p>
                  Certains tronçons du réseau routier secondaire sont
                  déconnectés et comprennent des impasses. Ainsi, près de 80 %
                  du total des routes de la ville de Kinshasa ne sont toujours
                  pas revêtus et sont parsemés de nids de poule à de nombreux
                  endroits, ce qui ralentit la circulation et provoque d’énormes
                  embouteillages presque tous les jours.
                </p>
                <p>
                  Pour répondre à la croissance significative de la population
                  et de la demande en transport, il sera essentiel d’établir un
                  réseau routier efficace et efficient grâce à la construction
                  de nouvelles routes et à l’amélioration des routes existantes
                  afin de disperser le flux de trafic et de promouvoir les
                  activités urbaines.
                </p>
              </div>
              <div className="img-c">
                <img
                  src="/imgs/about-pdtk/reseau-incomplet.jpg"
                  alt="Demande de trafic"
                />
              </div>
            </div>
            {/* s3 */}
            <div className="s--wrapper">
              <div className="img-c">
                <img
                  src="/imgs/about-pdtk/transport-en-commun.jpg"
                  alt="Demande de trafic"
                />
              </div>
              <div className="d--content">
                <h3>
                  Transport routier en commun peu réglementé et exploité par le
                  secteur privé
                </h3>
                <p>
                  Les transports publics sont moins réglementés et beaucoup plus
                  gérés par les chauffeurs privés et individuels que par le
                  service officiel, TRANSCO.
                </p>
                <p>
                  Les chauffeurs de transport en commun privé se font
                  concurrence pour attirer les passagers si bien qu’ils n’ont
                  pas d’arrêts de bus désignés et cela ne les dérange pas
                  d’arrêter leur véhicule sur la route, souvent aux
                  intersections les plus fréquentées et devant les marchés et
                  les écoles pour monter et descendre les passagers.
                </p>
                <p>
                  La plupart des chauffeurs attendent que les autobus soient
                  pleins avant de quitter les terminaux, et ils peuvent arrêter
                  le service au milieu des itinéraires ou changer d’itinéraire
                  lorsque la demande diminue.
                </p>
              </div>
            </div>
            {/* s5 */}
            <div className="s--wrapper">
              <div className="d--content">
                <h3>Enjeux environnementaux</h3>
                <div>
                  <p>
                    La pollution environnementale devient sévère dans la zone
                    urbanisée de la ville. D’une part, les routes non bitumées
                    et la forte congestion routière dans plusieurs endroits mais
                    également, les points de collecte des déchets au bord de la
                    route sont des causes de pollution de l’air et des odeurs.
                  </p>
                  <p>
                    D’autre part, les bruits quotidiens, tels que la musique
                    amplifiée, les voix fortes des chauffeurs de bus sont la
                    cause principale de pollution sonore
                  </p>
                </div>
              </div>
              <div className="img-c">
                <img
                  src="/imgs/about-pdtk/enjeux-environnementaux.jpg"
                  alt="Demande de trafic"
                />
              </div>
            </div>
            {/* s6 */}
            <div className="s--wrapper">
              <div className="img-c">
                <img
                  src="/imgs/about-pdtk/securite.jpg"
                  alt="Demande de trafic"
                />
              </div>
              <div className="d--content">
                <h3>Sécurité routière</h3>
                <div>
                  <p>
                    De nombreux problèmes sur la sécurité routière ont été
                    observés dans la ville de Kinshasa : excès de vitesse,
                    dépassement dangereux, le manque de discipline de la part
                    des conducteurs, conduite sous l’emprise d’un état
                    alcoolique, véhicules en stationnement illégal, surcharge de
                    marchandises et de passagers, absence de panneaux de
                    signalisation, absence les installations adéquate destinées
                    aux piétons et aux autres usagers vulnérables de la route…
                    En plus, les conducteurs n’ont pas l’habitude de porter leur
                    ceinture de sécurité ou de ne pas porter de casque de moto,
                    pour le taxi-moto.
                  </p>
                  <p>
                    A cela s’ajoute que de nombreux feux de circulation ne sont
                    pas en service aux intersections de la ville de Kinshasa
                    mais, également, le contrôle inadéquat du trafic engendre
                    une faible performance de l’opération aux intersections.
                  </p>
                </div>
              </div>
            </div>
            {/* s7 */}
            <div className="s--wrapper">
              <div className="d--content">
                <h3>Un Service ferroviaire urbain dysfonctionnel</h3>
                <div>
                  <p>
                    En ce qui concerne le transport ferroviaire, seule la ligne
                    de la Gare Centrale à Kasangulu est encore en service mais
                    son exploitation est assez limitée. La ligne vers l’aéroport
                    de N’djili a été interrompue depuis le 18 septembre 2015 en
                    raison de problèmes financiers, tandis que la ligne vers
                    Kintambo a mis fin à l’exploitation du train en 2007.
                  </p>
                  <p>
                    Le service ferroviaire urbain actuel avec un aller-retour
                    par jour de la semaine est très loin du système de transport
                    urbain moderne des autres régions métropolitaines du monde.
                    En raison du nombre limité de locomotives, les seuls trains
                    exploités pour la zone urbaine de Kinshasa sont de Kasangulu
                    à Kinshasa le matin et de Kinshasa à Kasangulu le soir avec
                    une locomotive et huit wagons pour passagers.
                  </p>
                  <p>
                    Des problèmes sont observés dans presque tous les aspects du
                    service ferroviaire urbain, y compris l’exploitation, les
                    finances, l’infrastructure, le matériel roulant et
                    l’organisation institutionnelle. Pour que le service
                    ferroviaire fonctionne comme un élément important du système
                    de transport urbain, une intervention politique importante
                    est nécessaire.
                  </p>
                </div>
              </div>
              <div className="img-c">
                <img src="/imgs/about-pdtk/rail.jpg" alt="Demande de trafic" />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="aps--wrapper">
            <h1>Le PDTK</h1>
            <div className="s--wrapper">
              <div className="img-c text-center">
                <img
                  src="/imgs/logo-dark-01.png"
                  alt="PDTK"
                  style={{ "max-width": "250px", width: "100%" }}
                />
              </div>
              <div className="d--content">
                <p>
                  Compte tenu de la situation urbaine actuelle de la ville de
                  Kinshasa, il est évident que si rien n’est fait, la
                  concentration de la population à Kinshasa engendrera des
                  difficultés dans les conditions de vie sociale et
                  d’environnement naturel de la ville.
                </p>
                <p>
                  Afin de contribuer donc à la résolution des problèmes de
                  transport urbain dans la ville de Kinshasa, le Plan directeur
                  des transports urbains de la ville de Kinshasa (PDTK) a été
                  formulé en 2018 par le ministère des Infrastructures et des
                  Travaux publics (ITP) en partenariat avec l’Agence japonaise
                  de coopération internationale (JICA).
                </p>
                <p>
                  Le processus de formulation du PDTK a impliqué un ensemble
                  d’acteurs, dont diverses entités du gouvernement national
                  telles que le ministère de l’ITP et le ministère des
                  Transports et de la Communication (TVC), mais aussi du
                  gouvernement provincial de Kinshasa. Le PDTK a été
                  officiellement approuvé par le conseil provincial de Kinshasa
                  en 2019.
                </p>
                <p>
                  Le PDTK prévoit un réseau de développement des transports pour
                  soutenir les activités économiques urbaines, pour assurer
                  l’équité dans les transports, pour améliorer la sécurité et la
                  sûreté, et pour réaliser des transports respectueux de
                  l’environnement. Selon ces objectifs pour le transport urbain
                  à Kinshasa, au total, 120 projets à court, moyen et long terme
                  à partir de 8 domaines sont proposés.
                </p>
                <p>
                  D’ici 2030, les 20 millions d’habitants de Kinshasa pourront
                  se déplacer facilement et chacun aura le droit de choisir le
                  moyen de transport approprié qui répond le mieux à ses besoins
                  quotidiens.
                </p>
                <p>
                  La croissance étonnante de Kinshasa aura ébloui l’Afrique
                  subsaharienne et retenu l’attention de tous grâce à une
                  meilleure mobilité à Kinshasa qui sera livrée à la Kinoise par
                  le PDTK
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default AboutPDTK
