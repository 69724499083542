/*
    @Numerik
    Fichier : api.js
    Created on: 22 Février 2022 11:39:37
*/

export const host =
  process.env.NODE_ENV !== "production"
    ? "http://192.168.1.65:8000"
    : "https://pdtk-dissemination.com/api"

export const STORE_CONTACT = host + "/contacts"
