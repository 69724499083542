/**
 * @Numerik
 */
import React from "react"
import { Link } from "react-router-dom"
import { Documentation } from "../../routes"

const About = () => {
  return (
    <div className="hp--abt">
      <div className="abt--wrapper">
        <div className="hpa--caption-c">
          <small className="section--info">A propos</small>
          <h1 className="cw--title">
            Le PDTK, <span> projet salvateur </span>pour Kinshasa.
          </h1>
          <p>
            {/* Le Plan Directeur des transports urbain de Kinshasa (PDTK) est un document référentiel qui détaille le scénario à forte intensité de transport en commun basé principalement sur la modernisation de chemin de fer et l’introduction du BRT (Bus à haut niveau de service/ Bus Rapid Transit) pour répondre à la demande du trafic de la ville de Kinshasa d’ici 2030.<br /><br /> */}
            Le PDTK, élaboré pour l'horizon 2030 à moyen terme et 2040 à long
            terme vise à amerliorer le système de mobilité et de transport de la
            ville de Kinshasa de manière à l'adapter aux besoins réels et à la
            portée de bourse des Kinois.
            <br />
            <br />
            {/* Le PDTK s’aligne sur la vision nationale de développement économique à l’horizon 2030 contenue dans le Plan national stratégique de développement. */}
            Réparti en 8 secteurs, il poursuit 4 objectifs majeurs à savoir : :
            <br />
          </p>
          <ul>
            <li>Soutenir les activités économiques urbaines</li>
            <li>Assurer l’équité dans les transports</li>
            <li>Améliorer la sécurité et la sureté</li>
            <li>Réaliser des transports respectieux de l'environement</li>
          </ul>
          <div className="actionner">
            <Link
              to={Documentation}
              className="btn btn-secondary btn-actionner"
            >
              Documentation
            </Link>
          </div>
        </div>
        <div className="images-c">
          <img src="/imgs/home/h--about.png" alt="about" />
        </div>
      </div>
    </div>
  )
}

export default About
