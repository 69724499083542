/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/pages/Dashboard.js
Created On : 15 May 2022 11:45:25
*/

import React, { useState } from "react"
import MasterPlan from "../components/dashboard/MasterPlan"
import Kinshasa from "../components/dashboard/Kinshasa"

const Dashboard = () => {
  const [activeSection, setActiveSection] = useState("plan")

  return (
    <>
      <div className="dashboard-p-c">
        <div className="wrapper py-4">
          <ul className="">
            <li>
              <div onClick={(e) => setActiveSection("plan")}>
                <div
                  className={`it-displayer ${
                    activeSection === "plan" ? "active" : ""
                  }`}
                >
                  <div className="caption">
                    <h6 className="itd--title">PDTK Masterplan Dashboard</h6>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div onClick={(e) => setActiveSection("kinshasa")}>
                <div
                  className={`it-displayer ${
                    activeSection === "kinshasa" ? "active" : ""
                  }`}
                >
                  <div className="caption">
                    <h6 className="itd--title">Kinshasa Transport Dashboard</h6>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      {activeSection === "plan" ? <MasterPlan /> : <Kinshasa />}
    </>
  )
}

export default Dashboard
