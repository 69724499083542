/**
 * @Numerik
 */

import React from "react"
import { Routes, Route } from "react-router-dom"
import Home from "../components/home"
import MyBrtComponent from "../components/mybrt"
import MyTrainComponent from "../components/mytrain"
import DocumentationComponent from "../components/Documentation"
import ContactsComponent from "../components/Contact"
import ErrorNotFound from "../components/errors/404"
import {
  HOME,
  MyBRT,
  Documentation,
  Contacts,
  MyTrain,
  DASHBOARD,
  SECTORS,
  ABOUT,
} from "."
import Dashboard from "../pages/Dashboard"
import Sectors from "../pages/Sectors"
import AboutPDTK from "../pages/AboutPDTK"
const router = () => {
  return (
    <Routes>
      <Route path={HOME} element={<Home />} />
      <Route path={MyBRT} element={<MyBrtComponent />} />
      <Route path={MyTrain} element={<MyTrainComponent />} />
      <Route path={Documentation} element={<DocumentationComponent />} />
      <Route path={Contacts} element={<ContactsComponent />} />
      <Route path={DASHBOARD} element={<Dashboard />} />
      <Route path={SECTORS} element={<Sectors />} />
      <Route path={ABOUT} element={<AboutPDTK />} />

      {/* 404 */}
      <Route path="*" element={<ErrorNotFound />} />
    </Routes>
  )
}

export default router
