/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/sectors/Traffic.js
Created On : 16 May 2022 17:35:11
*/

import React from "react"

const Traffic = () => {
  return (
    <div>
      <h2>13 Projets de gestion du trafic</h2>
      <p>
        L’amélioration du goulot d’étranglement se compose des projets suivants
      </p>
      <ul>
        <li>Amélioration des intersections</li>
        <li>Système de commande des feux de circulation</li>
        <li>Gestion de stationnement sur voiries</li>
        <li>
          Contrôle de stationnement illégal au moyen de l’application de société
          privée
        </li>
      </ul>
      <p>
        Il est à noter que de gros embouteillages se produisent pendant les
        heures de pointe à cause d’intersections ayant une mauvaise conception
        géométrique ne permettant pas de gérer un grand volume de trafic, en
        dépit des feux de circulation existants. Ainsi, il est proposé
        d’introduire une combinaison d’une voie à sens unique, afin d’optimiser
        le flux du trafic et de réduire les points de conflit.{" "}
      </p>
      <p>
        Un système de contrôle des signaux coordonné (réglage du décalage) sera
        mis en place afin de coordonner les opérations des feux de circulation
        et de garantir la fluidité du trafic.
      </p>
      <p>
        En ce qui concerne la gestion du Parking, un programme approprié de
        gestion de stationnement sera élaboré pour augmenter la capacité du
        trafic. Il se compose des éléments suivants :
      </p>
      <ul>
        <li>
          La révision de la politique et de la planification de l’aménagement
          des installations de parkings ;
        </li>
        <li>
          La gestion de stationnement sur voirie, y compris le contrôle des
          taxis par un système d’enregistrement adéquat et installation de
          minibus et de parkings de taxis ;
        </li>
        <li>L’application de la loi sur le stationnement non autorisé ;</li>
        <li>
          L’aménagement d’une installation de parking hors voirie exploitée par
          les secteurs privé et public ; et
        </li>
        <li>L’Introduction d’un système de navigation de Parking.</li>
      </ul>
      <div className="mb-3">
        <h1 className="h3 text-muted my-2">Projets proposés</h1>
        <p>
          <img
            src="/imgs/secteurs/p-traffic-01.png"
            alt="carte"
            className="w-100"
          />
        </p>
      </div>
      <div className="mb-3">
        <p>
          <img
            src="/imgs/secteurs/p-legend-01.png"
            alt="carte"
            className="w-100"
          />
        </p>
      </div>
    </div>
  )
}

export default Traffic
