/*
    @Numerik
    Fichier : Documentation.js
    Created on: 31 Janvier 2022 16:03:27
*/

import React from "react"
import { Card, Table } from "react-bootstrap"

const base_url =
  process.env.NODE_ENV !== "production"
    ? "http://localhost:3002"
    : "https://pdtk-kinshasa.com"

const Documentation = () => {
  return (
    <>
      <div className="docs-p py-5">
        <div className="docs--wrapper mb-3">
          <h1 className=" h2 text-muted titled-font mb-5">
            Documentation en Français
          </h1>

          <Card className="mb-5">
            <Card.Body>
              <Card.Title>
                Profils des Projets Proposés. Annexe 1 de Volume 1.
              </Card.Title>
              <div className="table-responsive">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Matière</th>
                      <th>Lien pour le fichier PDF</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Annexe 1 de Volume 1</td>
                      <td>
                        <a
                          href={base_url + "/docs/" + "VOL1_ANNEX1_FR.pdf"}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {base_url + "/docs/" + "VOL1_ANNEX1_FR.pdf"}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>

          <Card className="mb-5">
            <Card.Body>
              <Card.Title>
                Projet d'élaboration du Plan Directeur des Transports Urbains de
                la ville de Kinshasa -PDTK-Rapport Final, Résumé de Volume 1,
                Avril 2019
              </Card.Title>
              <div className="table-responsive">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Matière</th>
                      <th>Lien pour le fichier PDF</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Cover ~ Chapter 8</td>
                      <td>
                        <a
                          href="https://libopac.jica.go.jp/images/report/12340337.pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://libopac.jica.go.jp/images/report/12340337.pdf
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>

          <Card className="mb-5">
            <Card.Body>
              <Card.Title>
                Projet d'élaboration du Plan Directeur des Transports Urbains de
                la Ville de Kinshasa - PDTK - rapport final , Volume 1, Avril
                2019
              </Card.Title>
              <div className="table-responsive">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Matière</th>
                      <th>Lien pour le fichier PDF</th>
                    </tr>
                  </thead>
                  <tbody>
                    {docs.map((doc, key) => (
                      <tr key={key}>
                        <td>{doc.title}</td>
                        <td>
                          <a href={doc.link} target="_blank" rel="noreferrer">
                            {doc.link}
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="docs--wrapper mb-3">
          <h1 className=" h2 text-muted titled-font mb-5">
            English Documentation
          </h1>

          <Card className="mb-5">
            <Card.Body>
              <Card.Title>
                Proposed Projects Profiles, Appendix1 of Volume 1
              </Card.Title>
              <div className="table-responsive">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Subject</th>
                      <th>Link for PDF file</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Appendix1 of Volume 1</td>
                      <td>
                        <a
                          href={base_url + "/docs/" + "VOL1_EN_ANNEX1.pdf"}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {base_url + "/docs/" + "VOL1_ANNEX1_EN.pdf"}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>

          <Card className="mb-5">
            <Card.Body>
              <Card.Title>
                Project for Urban Transport Master Plan in Kinshasa City-PDTK-
                Final Report, Summary of Volume 1, April 2019
              </Card.Title>
              <div className="table-responsive">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Subject</th>
                      <th>Link for PDF file</th>
                    </tr>
                  </thead>
                  <tbody>
                    {docs2.map((doc, key) => (
                      <tr key={key}>
                        <td>{doc.title}</td>
                        <td>
                          <a href={doc.link} target="_blank" rel="noreferrer">
                            {doc.link}
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
          <Card className="mb-5">
            <Card.Body>
              <Card.Title>
                Project for Urban Transport Master Plan in Kinshasa City -PDTK-
                Final Report, Volume 1, April 2019
              </Card.Title>
              <div className="table-responsive">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Subject</th>
                      <th>Link for PDF file</th>
                    </tr>
                  </thead>
                  <tbody>
                    {docs3.map((doc, key) => (
                      <tr key={key}>
                        <td>{doc.title}</td>
                        <td>
                          <a href={doc.link} target="_blank" rel="noreferrer">
                            {doc.link}
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  )
}

export default Documentation

const docs = [
  {
    title: "Cover ～ Chapter 3",
    link: "https://libopac.jica.go.jp/images/report/12340345_01.pdf",
  },
  {
    title: "Chapter 4 ～ Chapter 7",
    link: "https://libopac.jica.go.jp/images/report/12340345_02.pdf",
  },
  {
    title: "Chapter 7 ~ Chapter 9",
    link: "https://libopac.jica.go.jp/images/report/12340345_03.pdf",
  },
]
const docs2 = [
  {
    title: "Cover ～ Chapter 5",
    link: base_url + "/docs/" + "EIJR19058 PDTK_En_V1-Sum.pdf",
  },
  {
    title: "Chapter 6 ~ Chapter 8",
    link: base_url + "/docs/" + "EIJR19058 PDTK_En_V1-Sum02.pdf",
  },
]
const docs3 = [
  {
    title: "Cover ~ Chapter 2",
    link: base_url + "/docs/" + "EIJR19058 PDTK_En_V1-Main01.pdf",
  },
  {
    title: "Chapter 3 ~ Chapter 4",
    link: base_url + "/docs/" + "EIJR19058 PDTK_En_V1-Main02.pdf",
  },
  {
    title: "Chapter 5 ~ Chapter 6",
    link: base_url + "/docs/" + "EIJR19058 PDTK_En_V1-Main03.pdf",
  },
  {
    title: "Chapter 7.1 ~ 7.3",
    link: base_url + "/docs/" + "EIJR19058 PDTK_En_V1-Main04.pdf",
  },
  {
    title: "Chapter 7.4 ~ 7.8",
    link: base_url + "/docs/" + "EIJR19058 PDTK_En_V1-Main05.pdf",
  },
  {
    title: "Chapter 8 ~ Chapter 9",
    link: base_url + "/docs/" + "EIJR19058 PDTK_En_V1-Main06.pdf",
  },
]
