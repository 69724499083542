/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/pages/Sectors.js
Created On : 15 May 2022 13:20:55
*/

import React, { useState } from "react"
import SectorsSection from "../components/home/Sectors"
import Brt from "../components/sectors/Brt"
import Bus from "../components/sectors/Bus"
import Ferrovier from "../components/sectors/Ferrovier"
import Finance from "../components/sectors/Finance"
import Maritime from "../components/sectors/Maritime"
import Routiers from "../components/sectors/Routiers"
import Security from "../components/sectors/Security"
import Traffic from "../components/sectors/Traffic"

const Sectors = ({ title, subtitle }) => {
  const [selected, setSeclected] = useState("brt")

  const Sections = () => {
    switch (selected) {
      case "brt":
        return <Brt />
      case "ferrovier":
        return <Ferrovier />
      case "bus":
        return <Bus />
      case "routes":
        return <Routiers />
      case "traffic":
        return <Traffic />
      case "security":
        return <Security />
      case "maritime":
        return <Maritime />
      case "finance":
        return <Finance />

      default:
        return <Ferrovier />
    }
  }

  return (
    <div className="sctrs--page">
      <div className="wrapper">
        <SectorsSection
          title="LE PDTK PAR SECTEUR"
          subtitle="Le PDTK propose huit axes de développement dont :"
          activeSection={selected}
          setSelectedSection={setSeclected}
        />
        <div className="sc--s">
          <Sections />
        </div>
      </div>
    </div>
  )
}

export default Sectors
