/**
 * @Numerik
 */

import React, { useState } from "react"
import { setValidationErrors } from "../errors/validation"
import { useMakesRequest } from "../request"
import { STORE_CONTACT } from "../routes/api"
import { makeToastNotification } from "../Toaster"
import { Input, Textarea, Button } from "./utilities/forms"
const Contact = () => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")
  const [errors, setErrors] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const makeRequest = useMakesRequest()

  function onSubmit(e) {
    e.preventDefault()
    makeRequest({
      method: "post",
      route: STORE_CONTACT,
      data: {
        prenom: firstName,
        nom: lastName,
        email: email,
        phone: phone,
        sujet: subject,
        message: message,
      },
      setLoader: setIsLoading,
      onSuccess: onSuccess,
      onError: onError,
    })
  }

  function onSuccess(response) {
    if (response.status === 201) {
      makeToastNotification({ message: response.data, duration: 5000 })
      resetData()
    }
  }

  //
  function onError(error) {
    setValidationErrors(setErrors, error)
  }

  //
  function resetData() {
    setFirstName("")
    setLastName("")
    setEmail("")
    setPhone("")
    setSubject("")
    setMessage("")
    setErrors([])
  }

  return (
    <div className="p--contacts">
      <div className="c--wrapper">
        <div className="fom-side">
          <div className="fs--hdr">
            <small className="section--info">Nous contacter</small>
            <h1 className="cw--title">
              {" "}
              Get in <span>Touch</span>{" "}
            </h1>
          </div>

          <form method="post" onSubmit={onSubmit}>
            <div className="form--wrapper">
              <div className="row form-group">
                <div className="col-md-6">
                  <Input
                    disabled={isLoading}
                    placeholder="Prenom"
                    name="prenom"
                    value={firstName}
                    onChange={setFirstName}
                    errorMessage={errors.prenom}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    disabled={isLoading}
                    placeholder="Nom"
                    name="nom"
                    value={lastName}
                    onChange={setLastName}
                    errorMessage={errors.nom}
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-6">
                  <Input
                    disabled={isLoading}
                    name="email"
                    type="email"
                    placeholder="E-mail"
                    value={email}
                    onChange={setEmail}
                    errorMessage={errors.email}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    disabled={isLoading}
                    type="tel"
                    name="telephone"
                    placeholder="Téléphone"
                    value={phone}
                    onChange={setPhone}
                    errorMessage={errors.phone}
                  />
                </div>
              </div>
              <Input
                disabled={isLoading}
                name="sujet"
                placeholder="Sujet"
                value={subject}
                onChange={setSubject}
                errorMessage={errors.sujet}
              />
              <Textarea
                disabled={isLoading}
                name="messagez"
                placeholder="Message"
                value={message}
                onChange={setMessage}
                errorMessage={errors.message}
              />
              <div className="actionner">
                <Button
                  disabled={isLoading}
                  isLoading={isLoading}
                  type="submit"
                  className="btn btn-secondary btn-actionner w-100"
                >
                  Envoyer
                </Button>
              </div>
            </div>
          </form>
        </div>
        {/* infos */}
        <div className="infos--s">
          <h1 className="cw--title">
            Nos autres <span>adresses</span>
          </h1>
          <div className="info--item">
            <h2>Kinshasa</h2>
            <p>
              Numéro 112, Avenue Pumbu,
              <br /> Quartier Batetela, <br />
              Commune de la Gombe
            </p>
            <ul>
              <li>
                Tel:{" "}
                <span>
                  {" "}
                  <a href="tel:+243 823 322 272" className="text-secondary">
                    +243 823 322 272
                  </a>
                </span>{" "}
              </li>
              <li>
                E-mail:{" "}
                <span>
                  {" "}
                  <a
                    href="mailto:contact@pdtk-kinshasa.com"
                    className="text-secondary"
                  >
                    contact@pdtk-kinshasa.com
                  </a>
                </span>{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
