/**
 * @Numerik
 */

import React from "react"

const Partners = () => {
  return (
    <div className="hp--ptrns">
      <div className="p--wrapper">
        {[
          { img: "gouv-01.png", url: "https://twitter.com/minitp_rdc" },
          { img: "ville.jpg", url: "" },
          { img: "cellule.jpg", url: "https://www.celluleinfra.org/" },
          { img: "jica.jpg", url: "https://www.jica.go.jp/french/index.html" },
        ].map((x, j) => (
          <div className="it--displayer">
            <a
              key={j}
              href={x.url}
              className="p--link"
              target="_blank"
              rel="noreferrer"
            >
              <img src={"/imgs/partners/" + x.img} alt={x.img} />
              {x.url && <div className="caption">Voir le site</div>}
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Partners
