/**
 * @Numerik
 *
 * main app
 */

import React, { useEffect } from "react"
import Header from "./components/Header"
import Footer from "./components/Footer"
import Router from "./routes/router"
import { Toaster } from "react-hot-toast"
import ReactGA from "react-ga"
const TRACKING_ID = "G-4ZWE9NFTXR"
ReactGA.initialize(TRACKING_ID)
const App = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])
  return (
    <div>
      <Header />
      <Router />
      <Footer />
      <Toaster />
    </div>
  )
}

export default App
