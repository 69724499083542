export const goals = [
  {
    section: "brt",
    icon: "/imgs/home/sectors/brt.png",
    number: 7,
    title: "Projets BRT",
    description:
      "Bus Rapid Transit (BRT) ou Bus à haut niveau de service,  est un système de bus de haute qualité qui offre des services de transport rapides, confortables et rentables. ",
    images: [],
  },
  {
    section: "ferrovier",
    icon: "/imgs/home/sectors/ferrovier.png",
    number: 2,
    title: "Projets ferroviaires",
    description:
      "Réhabilitation et modernisation de la ligne Sud Gare centrale-Kasangulu et de la ligne Est Gare centrale-Aéroport de N’djili.",
    images: [],
  },
  {
    section: "routes",
    icon: "/imgs/home/sectors/routier.png",
    number: 78,
    title: "Projets routiers",
    description:
      "Le réseau routier kinois sera étendu et modernisé avec la construction des plusieurs routes stratégiques, routes primaires et routes secondaires.",
    images: [],
  },
  {
    section: "security",
    icon: "/imgs/home/sectors/security.png",
    number: 11,
    title: "Sécurité routière",
    description:
      "Aménagement des stations BRT, des routes piétonnes confortables, et campagne permanente d’éducation et de sensibilisation à la sécurité routière.",
    images: [],
  },
  {
    section: "traffic",
    icon: "/imgs/home/sectors/gestion-traffic.png",
    number: 13,
    title: "Gestion du trafic",
    description:
      "Amélioration des feux de signalisation et implication des agents de l’ordre pour faire respecter la limitation de vitesses.",
    images: [],
  },
  {
    section: "bus",
    icon: "/imgs/home/sectors/bus.png",
    number: 4,
    title: "Bus et paratransit",
    description:
      "Organisation des transports en tenant compte des réalités de terrain et en les rendant un service de desserte pour le BRT et le train urbain.",
    images: [],
  },
  {
    section: "maritime",
    icon: "/imgs/home/sectors/fluvial.png",
    number: 2,
    title: "Transport fluvial",
    description:
      "Modernisation du port de Kinkolé et de quelques points d’accostage pour développer le transport sur le fleuve entre la partie Est de Kinshasa et le centre-ville.",
    images: [],
  },
  {
    section: "finance",
    icon: "/imgs/home/sectors/finance.png",
    number: 3,
    title: "Arrangements instit. & financiers",
    description:
      "Construction des nouveaux aménagements institutionnels et financiers suivant les 3 noyaux urbains en vue d’éviter la concentration démographique en un seul pole.",
    images: ["/imgs/sectors/organigamme_inst_fin.png"],
  },
]
export const goalsSecond = [
  {
    number: 13,
    title: "Gestion du trafic",
    description:
      "Amélioration des feux de signalisation et implication des agents de l’ordre pour faire respecter la limitation de vitesses.",
  },
  {
    number: 4,
    title: "Bus et paratransit",
    description:
      "Organisation des transports en tenant compte des réalités de terrain et en les rendant un service de desserte pour le BRT et le train urbain.",
  },
  {
    number: 2,
    title: "Transport fluvial",
    description:
      "Modernisation du port de Kinkolé et de quelques points d’accostage pour développer le transport sur le fleuve entre la partie Est de Kinshasa et le centre-ville.",
  },
  {
    number: 3,
    title: "Arrangements instit. & financiers",
    description:
      "Construction des nouveaux aménagements institutionnels et financiers suivant les 3 noyaux urbains en vue d’éviter la concentration démographique en un seul pole.",
  },
]
