/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/home/Sectors.js
Created On : 15 May 2022 12:33:50
*/
import React from "react"
import { Link } from "react-router-dom"
import { SECTORS } from "../../routes"
import { goals } from "../data/goals"

const Sectors = ({
  setSelectedSection,
  activeSection,
  title = "120 PROJECTS EN 8 DOMAINES",
  subtitle,
}) => {
  function toggleSection(e, s) {
    if (!setSelectedSection) {
      return
    }
    e.preventDefault()
    setSelectedSection(s)
  }
  return (
    <div className="hp--sectors">
      <div className="s--wrapper">
        <h1 className="hp--title">{title}</h1>
        <p>
          {subtitle
            ? subtitle
            : "Selon ces objectifs pour le transport urbain dans la ville de Kinshasa, au total, 120 projets à court, moyen et long terme à partir de 8 domaines sont proposés"}
        </p>

        <ul>
          {goals.map((item, key) => (
            <li key={key}>
              <Link
                onClick={(e) => toggleSection(e, item.section)}
                to={SECTORS}
              >
                <div
                  className={`it-displayer ${
                    activeSection === item.section ? "active" : ""
                  }`}
                >
                  <div className="ic-c">
                    <img alt={item.title} src={item.icon} />
                  </div>
                  <div className="caption">
                    <h6 className="itd--title">{item.title}</h6>
                    {/* <p>{item.description}</p> */}
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Sectors
