/**
 * @Numerik
 * App Header
 */

import React from "react"
import { Link } from "react-router-dom"
import {
  ABOUT,
  Contacts,
  DASHBOARD,
  Documentation,
  HOME,
  MyBRT,
  MyTrain,
  SECTORS,
} from "../routes"

const Header = () => {
  const TopBar = () => {
    return (
      <div className="m-upper--hdr">
        <div className="mu--wrapper">
          <div className="mu--ca">
            <a href="mailto:contact@pdtk-kinshasa.com" className="">
              {" "}
              <i className="fa-solid fa-envelope-open"></i>{" "}
              contact@pdtk-kinshasa.com
            </a>
            <a href="#" className="">
              {" "}
              <i className="fa-solid fa-location-dot"></i> Kinshasa
            </a>
          </div>
          <div className="mu--socials">
            <span>Suivez-nous : </span>
            <a
              href="https://www.facebook.com/PDTK-Kinshasa-108242641987568/"
              className=""
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <i className="fa-brands fa-facebook-f"></i>{" "}
            </a>
            <a
              href="https://twitter.com/Pdtk_kinshasa"
              target="_blank"
              className=""
              rel="noreferrer"
            >
              {" "}
              <i className="fa-brands fa-twitter"></i>{" "}
            </a>
            <a
              href="https://www.youtube.com/channel/UCaJteV7BCt00Wr8Hod7LfMA"
              className=""
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <i className="fa-brands fa-youtube"></i>
            </a>
            <a
              href="https://www.instagram.com/pdtk_kinshasa/"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <i className="fa-brands fa-instagram"></i>
            </a>
          </div>
        </div>
      </div>
    )
  }
  const MenusContent = () => {
    return (
      <ul className="menus--c">
        <li>
          <Link to={HOME}>Accueil</Link>
        </li>
        <li>
          <Link to={ABOUT}>A propos du PDTK</Link>
        </li>
        <li>
          <Link to={SECTORS}>Secteurs</Link>
        </li>
        <li>
          <Link to={DASHBOARD}>Dashboard</Link>
        </li>
        <li>
          <Link to={Documentation}>Documentation</Link>
        </li>
        {/* <li><Link to={Contacts}>Contact</Link></li> */}
        <li>
          <Link to={Contacts} className="btn-actionner btn-secondary btn ">
            Nous contacter
          </Link>
        </li>
      </ul>
    )
  }

  function toggleSmallMenus(e) {
    e.preventDefault()
    var element = document.getElementById("small-menus-content")
    element.classList.toggle("show")
  }

  const MainContent = () => {
    return (
      <div className="main--content">
        <div className="m--wrapper">
          <div className="logo-c">
            <Link to="/">
              <img alt="" src="/imgs/logo-white-01.png" />
            </Link>
          </div>
          {/* menus */}
          <div>
            <div className="lg-menus">
              <MenusContent />
            </div>
            <div className="sm-menus">
              <a
                href="/"
                className="small-menus-toggler"
                onClick={(e) => toggleSmallMenus(e)}
              >
                <i className="fa-solid fa-bars"></i>
              </a>
              <div className="small-menus-content" id="small-menus-content">
                <MenusContent />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="master--hdr">
      <TopBar />
      <MainContent />
    </div>
  )
}

export default Header
