/*
    @Numerik
    Fichier : 404.js
    Created on: 31 Janvier 2022 12:53:13
*/

import React from 'react';
import { Link } from 'react-router-dom';
import { HOME } from '../../routes';

const Error = () => {
    return (
        <div className="e-404">
            <div className="wrapper">
                <h1 className="title">404</h1>
                <h2 className="subtitle">On dirait que vous êtes perdu</h2>
                <p className="caption">la page que vous recherchez n'est pas disponible !</p>
                <Link to={HOME} className="btn btn-success">Retourner à l'accueil</Link>
            </div>

        </div>
    );
};

export default Error;