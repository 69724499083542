/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/components/sectors/Finance.js
Created On : 16 May 2022 17:37:24
*/
import React from "react"

const Finance = () => {
  return (
    <div>
      <h2>3 Projets d’arrangements institutionnels et financiers</h2>
      <p> Les 3 projets sont :</p>
      <ul>
        <li>Établissement et fonctionnement du cadre institutionnel</li>
        <li>Renforcement des capacités</li>
        <li>Préparation de la réussite du cadre institutionnel</li>
      </ul>
      <p>
        La mise en œuvre du PDTK et le suivi des travaux de mise en œuvre sont
        assurés par un conseil multipartite. Ce conseil est composé du comité
        directeur conjoint et du groupe de travail technique. Il est géré par le
        conseil d’administration, qui est composé du bureau du président, du
        bureau du Premier Ministre, du Ministre des Infrastructures et des
        Travaux publics, du Ministre des Transports et des Communications et du
        Gouverneur de la Ville. Une collaboration proactive entre les
        différentes institutions locales est essentielle pour la collaboration
        avec les organisations internationales et les investisseurs
        publics-privés.
      </p>
      <p>
        Il est également attendu que soit mis en œuvre un renforcement des
        capacités à court et moyen terme
      </p>
      <div className="mb-3">
        <h1 className="h3 text-muted my-2">Projets proposés</h1>
        <p>
          <img
            src="/imgs/secteurs/p-fin-01.png"
            alt="carte"
            className="w-100"
          />
        </p>
      </div>
      <div className="mb-3">
        <p>
          <img
            src="/imgs/secteurs/p-legend-01.png"
            alt="carte"
            className="w-100"
          />
        </p>
      </div>
      <div className="my-2">
        <img
          src="imgs/sectors/organigamme_inst_fin.png"
          alt=""
          className="w-100"
        />
      </div>
    </div>
  )
}

export default Finance
