/**
 * @Numerik
 */
import React from "react"
import Hiro from "./Hiro"
import Goals from "./Goals"
import SecondGoals from "./SecondGoals"
import Partners from "./Partners"
import Projections from "./Projection"
import About from "./About"
import Contact from "../Contact"
import PathPlan from "./PathPlan"
import Sectors from "./Sectors"
const index = () => {
  return (
    <div>
      <Hiro />
      {/* <Goals /> */}
      <About />
      <Sectors />
      <Projections />
      {/* <News /> */}
      <PathPlan />
      {/* <SecondGoals /> */}
      <Contact />
      <Partners />
    </div>
  )
}

export default index
