/*
    @Numerik
    Fichier : index.js
    Created on: 31 Janvier 2022 13:18:11
*/
import React from 'react';
import Sections from './Economy';
const index = () => {
    return (
        <div className="my-brt-p">
            <div className="hiro-c">
                <div className="c--overlay">
                    <div><h1>Mon BRT</h1></div>
                </div>
            </div>
            {/* intro */}
            <Sections />
        </div>
    );
};

export default index;